import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Typography } from '@popmenu/common-ui';

import { withIntl } from '../../utils/withIntl';
import { notBlankValidator } from '../../utils/forms';
import createPasswordResetMutation from '../../libs/gql/mutations/users/createPasswordResetMutation.gql';

import BasicForm, { SubmitGroup, TextFieldGroup } from '../../admin/shared/forms/BasicForm';

class ForgotForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reminderSent: false,
    };
  }

  render() {
    if (this.state.reminderSent) {
      return (
        <React.Fragment>
          <Typography gutterBottom>
            <FormattedMessage
              id="sessions.forgot_description"
              defaultMessage="If you have an account with us, you'll receive a link in your email to reset your password."
            />
          </Typography>
          <br />
        </React.Fragment>
      );
    }
    return (
      <BasicForm
        labelPosition="top"
        mutate={{
          mutation: createPasswordResetMutation,
          onCompleted: () => {
            this.setState({ reminderSent: true });
          },
          toVariables: values => ({
            ...values,
            restaurantId: this.props.restaurantId,
          }),
        }}
      >
        <TextFieldGroup
          data-cy="forgot_password_email"
          field="email"
          title="Email"
          type="email"
          validate={notBlankValidator}
        />
        <SubmitGroup
          data-cy="forgot_password_submit"
          block
          ButtonProps={{ textTransform: 'capitalize' }}
          title={this.props.t('sessions.forgot_send')}
        />
      </BasicForm>
    );
  }
}

ForgotForm.propTypes = {
  restaurantId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default withIntl(ForgotForm);
